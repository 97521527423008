import * as React from "react"
import * as styles from "./topTitle.module.scss"

const TopTitle = (): JSX.Element => (
  <div className={styles.pageContainer}>
    <div className={styles.content}>
      <h1 className={styles.title}>Your wish. Our command.</h1>
      <h4 className={styles.byline}>
        Genie delivers drinks, snacks, and essentials to your door in just 15
        minutes. We operate throughout the night, making sure that you get what
        you need, when you need it most!
      </h4>
    </div>
  </div>
)

export default TopTitle
