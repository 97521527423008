import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import BasicPage from "../templates/BasicPage"
import About from "../components/About"
import TopTitle from "../components/TopTitle"

const PrivacyPolicyPage = (): JSX.Element => (
  <Layout>
    <SEO
      title="About Us"
      description="Genie delivers drinks, snacks, and essentials to your door in just 15 minutes. We operate throughout the night, making sure that you get what you need, when you need it most!"
    />
    <BasicPage>
      <TopTitle />
      <About />
    </BasicPage>
  </Layout>
)

export default PrivacyPolicyPage
