import * as React from "react"
import * as styles from "./about.module.scss"

import map from "./img/map.png"
import partyPopper from "../../images/icons/emojis/party_popper.png"
import wrappedPresent from "../../images/icons/emojis/wrapped_present.png"
import coffee from "../../images/icons/emojis/coffee.png"

const topContent = [
  {
    title: "Download for free",
    emoji: partyPopper,
    description:
      "Download our Genie app and browse over 100 essential items, including chocolate, beer, condoms, and Ben & Jerrys.",
  },
  {
    title: "Tap and select",
    emoji: wrappedPresent,
    description:
      "Add items from our wide selection of brand favourites to your basket and order in seconds.",
  },
  {
    title: "Make a cuppa",
    emoji: coffee,
    description:
      "Our hyper local facility and experienced riders will deliver your goods straight to you in 15 minutes.",
  },
]

const bottomContent = [
  {
    title: "When can I order a Genie?",
    description:
      "We deliver from 6pm to 2am on Monday to Saturday. On Sunday, we’re open from 5pm!",
  },
  {
    title: "How much is Genie?",
    description:
      "We charge a flat £2 for delivery. Unlike *some* food delivery companies, there will be no service fees or small order fees.",
  },
  {
    title: "How did Genie start?",
    description:
      "After experiencing the lack of accessible late-night options at university, co-founders Tim and Callum made it their mission to provide a delivery service that makes it easier for students to get food, drinks, and essentials when shops are closed. Tim graduated from Cambridge, making it a natural location to trial their idea. Working with other ex-Cambridge students and tech developers based in Cambridge, the team brought Genie to life!",
  },
  {
    title: "What is Genie’s mission?",
    description:
      "Genie’s mission is to re-think convenience. Genie provides the solution for the modern-day and busy consumer and we make getting groceries and home essentials quick, easy, and affordable.",
  },
  {
    title: "How can I stay updated?",
    description: "Follow us on Facebook, Instagram, Twitter, and LinkedIn ",
  },
]

const FAQ = (): JSX.Element => (
  <div className={styles.pageContainer}>
    <h1 className={styles.title}>How does Genie work?</h1>
    <div className={styles.contentContainer}>
      {topContent.map((item) => (
        <div key={item.title} className={styles.item}>
          <h6 className={styles.itemTitle}>
            {item.title}{" "}
            <img className={styles.itemIcon} src={item.emoji} alt="" />
          </h6>
          <p className={styles.itemDescription}>{item.description}</p>
        </div>
      ))}
    </div>
    <div className={styles.contentContainer}>
      <h2 className={styles.mapTitle}>Where do we deliver?</h2>
      <div className={styles.mapWrapper}>
        <img className={styles.map} src={map} alt="" />
      </div>
    </div>
    <div className={styles.contentContainer}>
      {bottomContent.map((item) => (
        <div key={item.title} className={styles.item}>
          <h6 className={styles.itemTitle}>{item.title}</h6>
          <p className={styles.itemDescription}>{item.description}</p>
        </div>
      ))}
    </div>
  </div>
)

export default FAQ
